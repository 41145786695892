/* custome css for HTO */

:root {
    --main-color: #D71216;
    --second-color: #FBC23F;
    --third-color: #364A63;
    --grey-color: #232323;
    --white-color: #fff;
    --black-color: #000000;
    --white-80-color: #FFFEFE;
    --black-80-color: #0B0B0B;
}

.white-bg {
    background-color: var(--white-color);
}

.orange-bg {
    background-color: var(--second-color)
}

.red-bg {
    background-color: var(--main-color)
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

a {
    color: var(--black-color);
    text-decoration: none;
    font-weight: 600;
}

a:hover {
    text-decoration: underline;
    color: var(--third-color);
}

p {
    word-break: break-word;
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.red-text {
    color: var(--main-color);
}

.orange-text {
    color: var(--second-color);
}

.white-text {
    color: var(--white-color);
}

.orange-text:hover {
    color: var(--second-color);
    text-decoration: underline;
}

.blue-text,
.blue-text:hover {
    color: var(--third-col or);
}

.signin,
.signin:hover {
    background-color: var(--main-color);
    color: #fff;
    border-radius: 11rem;
    padding: 10px 20px !important;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 0;
    min-width: 120px;
    max-width: 240px;
    text-align: center;
}

.btn {
    border-radius: 100px;
    border: 0px;
    padding: 0.6rem 2.0rem !important;
    font-weight: bold;
    font-size: 0.9rem;
    ;
}

.btn-primary {
    background-color: var(--second-color);
    color: var(--black-80-color);
}

.btn-primary:hover {
    background-color: var(--main-color);
    color: var(--white-80-color);
}

.spl-btn {
    padding: 24px 40px;
    margin: 0px 10px;
}

.form-control,
.form-select {
    border: 1px solid #979797;
    min-height: 45px;
    line-height: 45px;
    border-radius: 0px;
}

.form-check-input {
    background-color: #D8D8D8;
    border-radius: 2px !important;
}

.form-check-input:checked {
    background-color: var(--second-color);
    border-color: var(--second-color);
}

.form-check-input:focus {
    border-color: rgb(251 194 63 / 74%);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(251 194 63 / 30%);
}

.choose-file {
    max-width: 128px !important;
    line-height: 36px;
    background: white !important;
    font-size: 18px;
    padding-left: 12px;
}

.choose {
    display: grid !important;
    grid-gap: 1rem;
    grid-template-columns: auto auto;
    align-items: center !important;
    justify-content: space-between;
}

.textarea {
    min-height: 250px !important;
}

.form-label {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.title-stay-connect {
    font-size: 48px;
    text-align: center;
}

.card {
    border: 1px solid #979797 !important;
}

.border-bottom {
    border-bottom: 1px solid #979797 !important;
}

.border-top {
    border-top: 1px solid #979797 !important;
}

.text-underline {
    text-decoration: underline;
}

.text-underline-no {
    text-decoration: none !important;
}

.close-ic {
    position: absolute;
    top: 0px;
    right: 0px;
}

.nav-tabs .nav-link {
    color: var(--third-color) !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 22px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--main-color) !important;
    background-color: #fff;
    border-color: #979797 #979797 #fff;
}

.nav-tabs {
    border-bottom: 1px solid #979797;
}

.main-menu .nav-item:last-child .dropdown-menu {
    /* background-color: green; */
    left: -85px;
}

.current-job-tbl table {
    border: 1px solid #979797 !important;
    padding: 4px;
}

.current-job-tbl table th {
    font-weight: 500;
    background-color: var(--third-color) !important;
    color: var(--white-color);
    border: 1px solid var(--third-color);
}

.current-job-tbl table th,
.current-job-tbl table td {
    line-height: 2rem;
    font-size: 16px;
    padding-left: 20px !important;
}

.current-job-tbl table td:nth-child(1) {
    width: 115px;
    text-align: center;
}

.current-job-tbl table td:nth-child(5) {
    width: 130px;
}


/* -------------------------------fonts------------------------------ */

@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins-Medium.woff2') format('woff2'), url('../font/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins-Regular.woff2') format('woff2'), url('../font/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins-SemiBold.woff2') format('woff2'), url('../font/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins-Bold.woff2') format('woff2'), url('../font/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'poppins' !important;
    font-weight: normal;
    font-size: 17px;
    color: var(--black-color);
}


/* Header  */

.main-header .nav-link {
    font-weight: normal !important;
}

@media screen and (max-width:768px) {
    .main-header {
        flex-direction: column;
        background-color: var(--black-color);
    }
}

.login-desktop {
    background-color: var(--main-color);
    color: var(--white-color);
    min-height: 60px;
    line-height: 60px;
    min-width: 80px;
    text-align: center;
}

.main-menu {
    width: 100%;
    z-index: 10;
    font-weight: normal;
}

.navbar-brand {
    width: 219px;
}

.navbar-brand img {
    max-width: 220px;
    width: auto;
    max-height: 90px;
}

.top-nav-bk {
    background-color: var(--black-color);
    z-index: 100;
}

.main-header {
    /* min-height: 87px;
	max-height: 87px; */
    /* background-color: var(--black-color); */
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.desktop-menu {
    flex-direction: column;
}

.desktop-menu .nav-link {
    color: var(--white-color);
    margin: auto 0.3rem;
    cursor: pointer;
}

.mobile-menu {
    display: none;
}

.mobile-menu .navbar .container {
    width: 100% !important;
    margin: 0px;
    padding: 0 20px;
    max-width: 100% !important;
}

.desktop-menu .main-menu {
    background-color: var(--grey-color);
    padding: 0.rem 0;
}

.desktop-menu .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: var(--grey-color);
}

.desktop-menu .navbar-brand img {
    max-width: 219px;
    width: auto;
    max-height: 90px;
}

.desktop-menu .navbar-nav .nav-link {
    padding: 2.5rem 0.4rem;
    color: var(--white-80-color);
    /* padding: 1.0rem 1.5rem; */
    font-size: 16px;
    font-weight: 400;
}

.home-page .desktop-menu .main-menu {
    background: none;
}

section.prime-banner .carousel-control-prev-icon {
    display: none;
}

.home-page .prime-banner {
    position: relative;
    max-height: calc(100vh - 60px);
    width: 100%;
    margin-top: -80px;
    z-index: 0;
    min-height: 480px;
}

.home-page .prime-banner .carousel-control-prev {
    max-width: 10px;
    display: none !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: var(--white-color);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: var(--second-color);
}

.headtitle-wrap {
    background-color: var(--white-color);
}

.headtitle {
    font-size: 30px;
    color: var(--black-color);
    font-weight: 600;
    margin-bottom: 0px;
}

.navbar-nav .dropdown-menu {
    margin: 0px;
    padding: 0px;
    border-radius: 0;
    background: var(--second-color);
    margin-top: 0px;
    left: 0px;
}

.dropdown-item {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgb(214 165 54);
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: 500;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--main-color);
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: rgba(255, 255, 255, 0.3);
}

.user-log-dropd .dropdown-menu {
    margin: 0px;
    padding: 0px;
    border-radius: 0;
    background: var(--second-color);
    margin-top: 0px;
    left: 15px;
    top: 14px !important;
}

.mob-nav {
    margin-top: 90px;
}

.mob-nav li {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mob-nav li:last-child {
    border: 0px;
}

.mob-nav a {
    padding-left: 25px !important;
    font-size: 20px;
    font-weight: 600;
    padding-right: 25px !important;
}

.mob-sub a {
    font-weight: 400 !important;
    font-size: 16px;
    padding-left: 24px !important;
    color: var(--white-color) !important;
}

.btn-toggle::before {
    line-height: 0;
    content: '';
    background: url(../../images/mob-arrow.png) no-repeat;
    transition: transform .35s ease;
    transform-origin: 0.5em 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 9px;
    background-size: 75%;
}


/* breadcrumb */

.breadcrumb {
    margin: 1rem 0px;
}

.breadcrumb-item.active,
.breadcrumb-item a {
    color: var(--black-color);
}


/* main */

.prime-banner {
    position: relative;
    max-height: calc(100vh - 60px);
    width: 100%;
    margin-top: -78px;
}

.banner-img {
    max-height: calc(100vh - 50px);
    object-fit: cover;
    object-position: top;
}

.carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 100px !important;
    border-top: 0px;
    border-bottom: 0px;
    opacity: 1;
}

.carousel-indicators {
    bottom: 50px;
}

@media screen and (max-width:768px) {
    .carousel-indicators {
        bottom: 5px;
    }
}

.carousel-indicators button {
    background-color: var(--white-color)
}

.carousel-indicators button.active {
    background-color: var(--second-color)
}

.carousel-button {
    background-color: var(--second-color);
    color: var(--black-80-color);
    border-radius: 3rem;
    padding: 10px 20px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.carousel-caption {
    left: 0px;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex !important;
    align-items: center;
}

.grey-button {
    background-color: var(--third-color);
    color: var(--white-color);
    border-radius: 3rem;
    padding: 10px 20px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.grey-button:hover {
    color: var(--white-color);
}

.login-button-pg a {
    width: 100%;
    background: var(--second-color);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    border: 0px;
}

.login-button-pg {
    width: 100%;
    background: var(--second-color);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    border: 0px;
}

.login-button-pg:hover {
    width: 100%;
    background: var(--main-color);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--white-color);
}

.login-button-pg a:hover {
    width: 100%;
    background: var(--main-color);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--white-color);
}

.apply-button {
    font-size: 13px;
    background-color: var(--second-color);
    padding: 3px 10px;
    border-radius: 100px;
}

.black-link {
    color: var(--black-color);
}

.carousel-caption p {
    margin-bottom: 2rem;
}


/* client wrap */

.typography-picture {
    display: grid;
    grid-template-columns: 70% 30%;
    background-color: var(--third-color);
    color: var(--white-color);
}

.typography-picture .typo-item:first-child {
    padding: 6rem;
}

.typography-picture-2 {
    display: grid;
    grid-template-columns: 30% 70%;
    background-color: var(--third-color);
    color: var(--white-color);
}

.typography-picture-2 .typo-item:last-child {
    padding: 6rem;
}

.object-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


/* Features */

.features {
    background-color: #232323;
    color: var(--white-color);
    padding: 20px 0px;
}

.features img {
    /* width: 36px; */
    height: 55px;
    image-rendering: -webkit-optimize-contrast;
}

.feature-item .title h4 {
    font-size: 1.3rem;
}

.county-button {
    background-color: var(--second-color);
    font-size: 15px;
    color: var(--black-color);
    border-radius: 11rem;
    padding: 2px 14px!important;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 0;
    min-width: 100px;
    max-width: 240px;
    text-align: center;
}

.reg-button {
    background-color: var(--second-color);
    color: var(--black-color);
    border-radius: 11rem;
    padding: 10px 20px !important;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 0;
    min-width: 120px;
    max-width: 240px;
    text-align: center;
}

.reg-button-close {
    background-color: var(--main-color);
    color: var(--white-80-color);
    border-radius: 11rem;
    padding: 10px 20px !important;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 0;
    min-width: 120px;
    max-width: 240px;
    text-align: center;
}

.reg-button-wage {
    background-color: var(--second-color);
    color: var(--black-color);
    border-radius: 11rem;
    padding: 6px 15px;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    border: 0;
    min-width: 120px;
}

.avl-but {
    position: absolute;
    left: 50%;
    bottom: -45%;
    transform: translate(-50%, -50px);
    z-index: 999;
}

.search-bar {
    position: relative;
}

.search-bar input {
    width: 100%;
    min-height: 50px;
    /* padding: 1.2rem; */
    border: 1px solid #979797;
    border-radius: 0px;
    position: relative;
}

.search-bar button {
    position: absolute;
    right: 5px;
    top: 40px;
    background-color: var(--white-color);
}

.dir-search {
    top: 4px !important;
    right: 4px;
    position: absolute;
    transform: scale(0.75);
    padding-left: 20px !important;
}

.alphabate {
    max-width: 600px;
    text-align: center;
    font-size: 15px;
}

.alphabate a {
    color: var(--black-color);
    line-height: 12px;
    font-size: 15px;
    padding: 0 6px;
    border-right: 1px solid #232323;
    margin-bottom: 5px;
    display: inline-block;
    text-align: center;
    font-weight: 600;
}

.alphabate a:last-child {
    border-right: 0px;
}

.counties {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.empr-directory-main section {
    position: relative;
}

.empr-directory-main {
    background: var(--second-color);
    position: relative;
}

.main-bgpatten::before {
    content: '';
    background-size: 1920px;
    background-color: var(--third-color);
    width: 100%;
    min-height: calc(100% - 2680px);
    background-image: url(../../images/PATTERN.png);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    height: 666px;
}

.empr-directory-wrap {
    border-bottom: 1px solid var(--grey-color);
    padding-bottom: 30px;
}

.empr-directory-wrap:last-child {
    border-bottom: 0px;
    padding-bottom: 10px;
}

.empr-directory-wrap ul {
    margin-left: 0px;
    padding: 0px;
}

.empr-directory-wrap ul li {
    list-style: none;
}

.empr-directory-wrap ul li a {
    color: var(--grey-color);
    font-weight: 500;
    font-size: 18px;
}

.empr-directory-wrap ul li a:hover {
    text-decoration: underline;
}

.pagination-wrap .page-link {
    border: 0px;
}

.page-item.active .page-link {
    z-index: 3;
    color: var(--grey-color);
    background-color: transparent;
    font-weight: 700;
    border-color: none !important;
    border-bottom: 2px solid var(--grey-color);
    /* text-decoration: underline; */
}

.page-item.active .page-link:hover {
    z-index: 3;
    color: var(--second-color);
    /* background-color:var(--main-color); */
    /* border-color: #0d6efd; */
}

.page-link:hover {
    z-index: 2;
    color: var(--second-color);
    background-color: var(--white-color);
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    color: var(--second-color);
    background-color: var(--white-color);
    outline: none;
}

.page-link {
    position: relative;
    outline: none;
    display: block;
    color: var(--grey-color);
    text-decoration: none;
    background-color: transparent;
    border: 0px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.page-item.disabled .page-link {
    background-color: transparent;
}

.bg-heading {
    background-color: var(--third-color);
    color: var(--white-color);
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.bg-heading h3 {
    background-color: var(--third-color);
    font-size: 30px;
    padding: 15px;
    font-weight: 500 !important;
}

.recent-job {
    padding: 0px;
    margin: 0px;
}

.recent-job li {
    list-style: none;
    margin-bottom: 15px;
}

.recent-job li a {
    display: grid;
    font-weight: 500;
}

.recent-job li a span:nth-child(1) {
    color: var(--main-color);
}

.recent-job li a span:nth-child(2) {
    color: var(--grey-color);
}

.stock-logo {
    max-width: 150px;
    min-width: 150px;
    object-fit: contain;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 15px;
    min-height: 90px;
    max-height: 90px;
}

.stock-wrap {
    display: grid;
    word-break: break-word;
}

.stock-wrap a {
    background-color: var(--second-color);
    color: var(--grey-color);
    padding: 12px;
    border-radius: 0px;
    max-width: 170px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
}

.stock-wrap h3 {
    font-size: 14px;
    min-height: 50px;
}

.stock-wrap span {
    min-height: 40px;
    font-size: 14px;
    min-height: 65px;
}

.qlinks {
    background-color: var(--third-color);
    padding: 20px;
}

.qlinks ul {
    padding: 0;
    margin-left: 10px;
}

.qlinks ul li {
    margin-bottom: 25px;
    margin-top: 25px;
    list-style: none;
}

.qlinks ul li:first-child {
    margin-top: 0px;
}

.qlinks ul li:last-child {
    margin-bottom: 0px;
}

.qlinks ul li a {
    font-size: 22px;
    color: var(--white-color);
    font-weight: 600;
}

.qlinks ul li a:active,
.qlinks ul li a:hover {
    color: var(--second-color);
    text-decoration: none;
}

.qlinks ul li .active {
    color: var(--second-color);
    text-decoration: none;
}

.orange-ribbon {
    background-color: var(--second-color);
    padding: 15px;
    border-radius: 10px;
}

.orange-ribbon span {
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--white-color);
    margin-left: 10px;
}

.orange-ribbon img {
    max-height: 67px;
}

.after-orange-ribbon h4 {
    margin-bottom: 6px;
    text-decoration: underline;
    font-size: 24px;
}

.card {
    border-radius: 0px;
    margin-bottom: 15px;
}

.oppertunity-card {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: 200px 1fr;
    border: 1px solid #979797;
    padding: 15px;
    margin-bottom: 30px;
}

.oppertunity-card2 {
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 200px 1fr 180px;
    border: 1px solid #979797;
    padding: 15px;
    margin-bottom: 30px;
}

.logo-org {
    width: 180px;
    min-width: 180px;
    height: 150px;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.logo-org img {
    padding: 5px;
    max-height: 100px;
    width: auto;
    max-width: 150px;
}

.video-section2 {
    display: grid;
    grid-template-columns: 320px auto;
    grid-gap: 2rem;
}

.video-item {
    background-color: transparent;
    width: 100%;
    min-height: auto;
}

.vidlib-cont-all {
    width: 100% !important;
    max-height: 220px;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
}

.vid-cont-all {
    width: 100%;
    height: 400px;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
}

.info-vid {
    padding-left: 20px;
    border-left: 1px solid;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.info-vid h4 {
    font-size: 18px;
    font-weight: 600;
}

.company-header {
    display: grid;
    grid-template-columns: 180px 1fr auto;
    align-items: start;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #979797;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.company-header .logo-org {
    border-right: 0px solid #979797;
    display: flex;
    align-items: start;
    /* width: auto; */
    height: auto;
}

.company-header .logo-org img {
    width: 100%;
    padding: 0 15px;
}

.company-header h4 {
    font-weight: 600;
    margin-bottom: 5px;
}

.company-header .right-info a {
    font-weight: 400;
    text-decoration: underline;
    color: var(--black-color);
}

.right-info h4 {
    text-decoration: underline;
}

.right-info .company-name {
    text-decoration: underline;
    font-weight: 400 !important;
    color: var(--black-color);
}

.quick-info {
    margin-top: 10px;
    padding: 0px;
}

.quick-info li {
    padding: 12px 10px 12px 0px;
    list-style: none;
    display: flex;
    align-items: center;
    word-wrap: anywhere;
}

.quick-info li span:nth-child(1) {
    width: 30px;
}

.quick-info li span:nth-child(2) {
    padding-left: 15px;
    font-weight: 500;
    font-size: 17px;
}

.quick-info img {
    max-width: 41px;
}

.company-detrail-cont p {
    margin-bottom: 20px;
}

.company-detrail-cont h4 {
    margin-bottom: 20px;
}

.company-detrail-cont h4,
.company-detrail-cont p,
.company-detrail-cont span,
.company-detrail-cont em,
.company-detrail-cont strong {
    margin-bottom: 20px;
    font-size: 17px !important;
    font-family: 'Poppins' !important;
    color: #000 !important;
    font-style: normal !important;
    text-align: left !important;
}

.culture-wrap {
    padding: 25px;
    border: 1px solid #979797;
    background-color: #FEFCF0;
}

.culture-wrap .culture-inn:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.culture-inn {
    display: grid;
    grid-template-columns: 27% 3% 70%;
    border-bottom: 1px solid #979797;
    padding: 0 0 30px;
    margin-bottom: 30px;
}

.vh-line {
    border-right: 1px solid #979797;
    margin-right: 20px;
}

.culture-head {
    font-size: 20px;
}

.culture-item {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    grid-gap: 15px 10px;
}

.culture-item img {
    max-width: 40px;
}

.culture-item span:nth-child(1) {
    margin-right: 10px;
}

.culture-item span:nth-child(2) {
    font-size: 13px;
}

.job-seeker {
    min-width: 150px;
    margin: 0 auto;
    border-left: 1px solid #979797;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* align-content: space-between; */
    align-self: stretch;
    padding-left: 30px;
}

.job-seek-icon {
    display: grid;
    grid-template-columns: 32px 32px 32px;
    grid-gap: 5px;
}

.job-seek-icon div {
    justify-content: center;
    display: flex;
}

.job-seek-icon img {
    max-height: 22px;
}


/* .category-check ul { display: grid; grid-template-columns: 1fr 1fr 1fr; margin: 0; padding: 0px;}
	  .category-check ul li { list-style: none;}
	  .category-check ul li label {margin-left:8px;}  */

.category-check {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    padding: 0px;
}

.category-check-itm ul {
    padding: 0px;
}

.category-check-itm li {
    margin-bottom: 10px;
    list-style: none;
    display: grid;
    grid-template-columns: 24px auto;
}

.category-check-itm li label {
    /* list-style: none;
    margin-left: 6px;
    color: var(--black-color);
    font-size: 0.9rem; */
    list-style: none;
    margin-left: 6px;
    color: var(--black-color);
    font-size: 0.9rem;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.category-check-perks ul {
    padding: 0px;
}

.category-check-perks li {
    margin-bottom: 20px;
    list-style: none;
    display: grid;
    grid-template-columns: 35px 45px auto;
    align-items: center;
}

.category-check-perks img {
    max-width: 32px;
    max-height: 32px;
}

.category-perks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 991px) {
    .category-perks {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 720px) {
    .category-perks {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.clamp {
    display: -webkit-box !important;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 70px;
    min-height: 40px;
}

.mh-75 {
    min-height: 75px;
}

.joblist-header {
    display: grid;
    grid-template-columns: 170px 1fr;
    align-items: flex-start;
}

.joblist-cmp-img {
    width: 140px;
    height: 140px;
    object-fit: contain;
    object-position: top;
    background: white;
}

@media (min-width:300px) and (max-width:640px) {
    .joblist-header {
        grid-template-columns: 1fr;
        padding: 15px;
        text-align: center;
    }
    .joblist-header .quickinfo-social {
        margin: auto;
        max-width: fit-content;
    }
}

.resume-wrap small {
    opacity: 0.5;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: none;
}

.locallife-carousel .carousel-control-next,
.carousel-control-prev {
    display: block !important;
    opacity: 1;
}

.locallife-carousel .carousel-control-next-icon,
.locallife-carousel .carousel-control-prev-icon {
    width: 4rem;
    height: 4rem;
    display: block !important;
}

.general-link {
    padding: 0;
    margin-top: 20px;
}

.general-link li {
    list-style: none;
}

.general-link li a {
    color: var(--black-color);
    font-weight: 400;
}

.general-link li a:hover {
    text-decoration: underline;
}

.quickinfo-social {
    display: grid;
    grid-template-columns: 45px 45px 45px 45px 45px;
    justify-content: space-around;
}

.quickinfo-social a:hover {
    transform: scale(1.1);
}

.quickinfo-social img {
    width: 40px;
    height: 40px;
}

.company-summery .stock-wrap {
    position: absolute;
    top: 40px;
    right: 30px;
}

.company-summery .date {
    position: absolute;
    top: 120px;
    right: 30px;
}

.v2-company-detail-banner {
    background-color: #ccc;
}

.v2-company-detail-banner img {
    max-height: 365px;
    object-fit: cover;
    width: 100%;
    object-position: top;
}

.v2-company-detail-wrap {
    display: grid;
    grid-template-columns: 180px auto 200px;
    position: relative;
}

.v2-company-detail-logo {
    width: 142px;
    height: 142px;
    border: 1px solid #ccc;
    display: block;
    margin-bottom: 15px !important;
}

.v2-company-detail-logo img {
    width: 140px;
    height: 140px;
    object-fit: contain;
    padding: 7px;
}

.v2-company-detail-info .border-right {
    border-right: 2px solid #ccc;
}

.v2-social img {
    width: 34px;
    height: 34px;
    margin-right: 10px;
}

.v2-br {
    border-right: 1px solid #ccc;
    padding-right: 15px;
    width: 100%;
}

.v2-vid-cd iframe {
    background-color: #000000;
}

.v2-control h2 {
    font-size: 18px;
}

.cs-tab {
    width: calc(100% - 105px);
}

@media screen and (max-width:991px) {
    .v2-br {
        border-right: 0px;
    }
    .v2-company-job {
        flex-direction: column;
    }
    .v2-mob-oj {
        justify-content: center !important;
        margin-top: 15px;
    }
    .industry-tag {
        justify-content: center;
    }
    .v2-info-mob {
        flex-direction: column !important;
    }
    .v2-social {
        margin: auto;
        justify-content: center;
    }
    .v2-sm-info {
        border-right: 0px !important;
        justify-content: center;
        margin: auto;
        width: 100%;
    }
    .v2-company-detail-wrap {
        grid-template-columns: 1fr;
    }
    .v2-company-detail-logo {
        width: 92px;
        height: 92px;
        margin: auto;
    }
    .v2-company-detail-info .d-flex {
        text-align: center;
        margin: auto;
    }
    .v2-company-detail-logo img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width:768px) {
    .company-summery .stock-wrap {
        position: relative !important;
        top: 12px !important;
        right: 0px;
    }
    .company-summery .date {
        position: relative !important;
        top: 0px !important;
        right: initial;
        text-align: center;
        width: 100%;
    }
    .company-summery .date b {
        justify-content: center;
    }
    .right-info h4 {
        text-align: center;
    }
    .cs-tab {
        display: grid !important;
        justify-content: center;
        text-align: center;
    }
    .cs-tab img {
        margin: auto;
    }
}

.company-summery .mb-4 img {
    width: 110px;
    height: 110px;
    object-fit: contain;
    object-position: top;
}

.job-perks {
    padding: 0;
}

.job-perks li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.job-perks li span {
    height: 35px;
    width: 35px;
    margin-right: 30px;
}

.job-perks li a {
    font-weight: 500;
}

.job-perks li span img {
    width: 100%;
}

.second-nav {
    padding: 0px 0px 0px 25px;
    text-transform: uppercase;
    margin-top: 30px;
}

.second-nav li {
    list-style: none;
    color: var(--white-color);
    margin-top: 15px;
    font-weight: 500;
    font-size: 18px;
}

.second-nav li a {
    font-weight: 400;
    font-size: 18px;
    padding: 0px;
    opacity: 0.9;
    color: var(--white-color);
}

.mobile-login {
    font-weight: 500 !important;
    color: var(--white-color) !important;
    background-color: var(--main-color);
    opacity: 1 !important;
    display: inline-block !important;
    padding: 10px 40px !important;
}

video {
    width: 100%;
    background: #ccc;
}

.login-wrap {
    background: var(--third-color) url('../../images/PATTERN.png');
}

.crs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
}

.crs h5 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 1px;
}

.crs a {
    color: var(--main-color);
}

.company-detail-mobile {
    border-bottom: 1px solid #979797;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

.company-txt-mobile h2 {
    font-size: 18px;
    margin-bottom: 0px;
}

.perks-mobile {
    background-color: #FEFCF0;
    border: 1px solid #979797;
    padding: 15px;
    text-align: center;
}

.perks-mobile h2 {
    font-size: 18px;
    margin-bottom: 1rem;
    font-weight: 500;
}

.perks-mobile hr {
    max-width: 70px;
    margin: auto;
    margin-bottom: 1rem;
}

.perks-mobile .carousel-item {
    min-height: 150px;
}

.perks-mobile .carousel-caption {
    text-align: left;
    transform: initial;
}

.perks-mobile .carousel-caption p {
    color: var(--black-color);
    text-shadow: none;
    margin-bottom: 0px;
    margin: auto;
    max-width: 180px;
    text-align: center;
}

.perks-mobile .carousel-control-next-icon,
.carousel-control-prev-icon {
    display: block;
}

.perks-mobile .carousel-control-prev-icon {
    background-image: url(../../images/left-arrow.png);
    background-size: 60%;
}

.perks-mobile .carousel-control-next-icon {
    background-image: url(../../images/right-arrow.png);
    background-size: 60%;
}

.perks-mobile .carousel-control-next,
.carousel-control-prev {
    opacity: 1;
}

.job-seek-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 15px;
    max-width: 240px;
    margin: auto;
    align-items: center;
    grid-gap: 15px 5px;
}

.job-seek-mobile img {
    max-height: 46px;
}

.job-seek-mobile div {
    text-align: center;
}

.js-more {
    font-size: 12px;
    text-decoration: underline;
}

.job-seek-modal {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    grid-gap: 1rem 1rem;
    padding: 0 15px;
}

.job-seek-modal div div {
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.job-seek-modal p {
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 18px;
}

.job-seek-modal img {
    max-height: 36px;
}

.mobile-form-avl {
    background-color: var(--third-color);
    background-image: url('../../images/PATTERN.png');
    max-height: 360px;
    overflow: auto;
}

.mobile-form-avl .category-check-itm li label {
    color: var(--white-color);
    margin-left: 15px;
}

.mobile-form-avl .category-check-itm li {
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
}

.mobile-form-avl .category-check-itm li input {
    margin-top: 0px;
    min-width: 18px;
    min-height: 18px;
}

.faq .accordion-header b {
    font-weight: 500 !important;
}

.qst {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: var(--main-color);
    color: #fff;
    margin-right: 10px;
    font-size: 0.75rem;
    display: block;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;
}

.ans {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: var(--second-color);
    color: #fff;
    margin-right: 10px;
    font-size: 0.75rem;
    display: block;
    text-align: center;
    line-height: 30px;
}

.accordion-button:not(.collapsed) {
    color: var(--grey-color);
    background-color: #fbc23f21;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:not(.collapsed)::after {
    filter: grayscale(1);
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button b {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
}

.accordion-collapse .accordion-body {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: flex-start;
}

.accordion-body p {
    font-size: 0.95rem;
}

.two-grid-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 1rem;
}

.grid-head {
    display: grid;
    grid-template-columns: 60px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #979797;
    margin-bottom: 10px;
}

.grid-head-career {
    display: grid;
    grid-template-columns: 70px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #979797;
    margin-bottom: 10px;
    align-items: center;
}

.grid-head-employer {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: start;
}

.grid-img {
    width: 45px;
    height: 45px;
    background-color: #ccc;
    border-radius: 100px;
}

.grid-img-company {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
}

.grid-img-empolyer {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
}

.grid-img img {
    border-radius: 100px;
}

.grid-title-wrap h4 {
    margin-bottom: 0px;
    font-size: 18px;
    word-break: break-word;
}

.grid-title-wrap .mr-2 {
    margin-right: 5px;
}

.grid-title-wrap small {
    font-size: 14px;
}

.grid-body-inn {
    display: grid;
    grid-template-columns: 130px auto;
    font-size: 14px;
    margin: 10px 0px;
    word-break: break-word;
}

.grid-body-inn span:first-child {
    font-weight: 600;
}

.grid-footer .reg-button {
    display: flex;
    text-align: center;
    margin: auto;
}

.grid-item {
    border: 1px solid #979797;
    word-break: break-all;
}

.video-watch-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.pp-footer {
    position: absolute;
    bottom: 15px;
    width: 100%;
}


/* HTO */


/* career box */

.career-box {
    background-color: var(--main-color);
    color: var(--white-color);
}

.video-section .title {
    color: var(--black-color);
}

.video-section .title h2 {
    line-height: 35px;
    font-weight: bold;
}


/* footer */

footer {
    padding: 30px;
    background-color: var(--black-color);
    color: var(--white-color);
    position: relative;
    z-index: 1;
}

footer .col-lg-5:nth-child(1) {
    padding-right: 180px;
}

.footer-link {
    margin: 0;
    padding: 0;
}

.footer-link li {
    list-style: none;
}

.footer-link li a {
    color: var(--white-color);
    font-weight: normal;
}

.footer-logo {
    max-width: 320px;
}

.social {
    display: flex;
}

.social ul {
    margin: 15px 0;
    padding: 0;
}

.social ul li {
    list-style: none;
    margin-right: 5px;
    float: left;
}

.social img {
    width: 49px;
    height: 49px;
}

.app-icons {
    margin-top: 10px;
}

.footer-sn-lnk,
.footer-sn-lnk:hover {
    color: var(--second-color);
    font-weight: 400;
    text-decoration: underline;
}


/* mobile hamburg nav */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--third-color);
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 10px;
}

.sidenav .navbar-nav {
    margin-top: 20px;
}

.sidenav .navbar-brand {
    margin: 0px;
}

.sidenav .navbar-light .navbar-nav .nav-link {
    padding: 1.0rem 1.0rem
}

.navOpened {
    width: 100%;
}

.sidenav a {
    padding: 3px 8px 8px 20px;
    text-decoration: none;
    color: var(--second-color);
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

#main {
    transition: margin-left .5s;
    padding: 16px;
}

.closeBtn {
    font-size: 30px;
    cursor: pointer;
    padding-top: 20px;
}

.closeBtn ul {
    display: none;
}

.icons {
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    z-index: 999;
    top: 20px;
}

.icons ul {
    margin: 0px;
    padding: 2px 7px 2px 7px;
}

.account-ic {
    width: 26px;
    height: 26px;
    position: relative;
    right: 30px;
}

.account-ic img {
    width: 100%;
}

.fa-plus {
    z-index: 100000000;
}

.menu-icon,
.close-icon {
    height: 3px;
    background-color: var(--white-color);
    width: 24px;
    margin: 6px 0;
    transition: all .25s ease-in-out;
}

.close-icon {
    background-color: white;
}

.opened .menu-icon:nth-child(1) {
    transform: rotate(45deg) translate(6px, 3.5px);
}

.opened .menu-icon:nth-child(2) {
    opacity: 0;
}

.opened .menu-icon:nth-child(3) {
    transform: rotate(-45deg) translate(9px, -7px);
}

.close-icon:nth-child(1) {
    transform: rotate(45deg) translate(6px, 5px);
}

.close-icon:nth-child(2) {
    transform: rotate(-45deg);
}

.mainOpened {
    margin-left: 300px;
}

.bodyOpened {
    background-color: rgba(0, 0, 0, 0.4);
    /* margin-left: 250px; */
    transition: all .25s ease-in-out;
}

#main-menu {
    padding-left: 36px;
    padding-top: 20px;
}

@media only screen and (min-width: 991px) {
    .sidenav {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        background-color: inherit;
        overflow-x: visible;
        transition: 0s;
        display: none;
        width: 100%;
        background-color: var(--white-color);
    }
    .sidenav ul {
        flex-direction: row;
    }
    .sidenav li {
        padding: 0 10px;
    }
    .menu-icon,
    .closeBtn {
        display: none;
    }
    #main-menu {
        padding: 0;
    }
}

.demoBody {
    padding-left: 50px;
    padding-top: 20px;
}

@media only screen and (max-width: 768px) {
    .nav-link {
        color: white !important;
    }
    .sidenav a {
        margin-left: 0px;
    }
}

.pro-banner-prev {
    max-height: 222px;
    object-fit: cover;
}

.banner-profile img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.grid-head-company {
    display: grid;
    grid-template-columns: 200px auto;
    margin-top: -50px;
}

.grid-img-com {
    width: 150px;
    height: 150px;
    background: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-head-company .grid-title-wrap {
    margin-top: 60px;
    margin-left: 15px;
}

.char150 {
    min-height: 100px;
}

.charmin200 {
    min-height: 200px;
}

.charmin235 {
    min-height: 235px;
}

.searchClsAlpha {
    cursor: pointer;
}

.hiring-img {
    /* max-height: 200px;
    min-height: 200px;
    width: 100% !important;
    object-fit: cover;
    object-position: top left; */
    max-height: 120px;
    min-height: 120px;
    width: 100% !important;
    object-fit: cover;
    object-position: center center;
}

.com-logo-indv {
    max-width: 180px;
    max-height: 180px;
}

.dt-highlt {
    font-size: inherit;
    font-weight: 600;
    color: #d71216;
}

.perk-algn img {
    max-width: 30px;
    max-height: 30px;
}

.perk-algn span {
    width: 30px;
    margin-right: 10px;
}


/* .perk-icon {
    display: grid;
    grid-template-columns: 40px auto;
} */

.user-picture img {
    object-fit: cover;
    width: 180px;
    height: 180px;
}

.reg-button {
    cursor: pointer;
}

.browse {
    max-width: 115px !important;
}

.addFav {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    border: 0;
}

.list-arrow li {
    margin-bottom: 1.2em;
}

.school-card {
    min-height: 240px;
    max-height: 240px;
}

.school-card img {
    max-height: 75px;
}

.school-img-wrp {
    min-height: 127px;
    max-height: 127px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}


/* stats & facts */

.sf {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    grid-gap: 2em;
    margin-bottom: 20px;
    word-break: break-word;
}

.sf ul {
    margin-left: -32px;
}

.sf ul li {
    list-style: none;
}

.sft {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    grid-gap: 2em;
    margin-bottom: 20px;
    word-break: break-word;
}

@media screen and (max-width: 640px) {
    .sf {
        grid-template-columns: 1fr !important;
    }
    .js-d-badge .culture-head {
        font-size: 15px;
    }
}

.f-disable {
    opacity: 0.3;
    cursor: not-allowed;
}

.form-check-input[type="radio"] {
    border-radius: 50% !important;
}

.wage-sur-inp {
    background-color: #fff4e0;
}

.wage {
    display: grid;
    grid-template-columns: 120px 1fr 1fr;
    justify-content: space-between;
    grid-gap: 1em;
    margin-bottom: 20px;
}

.wage label {
    font-size: 12px;
    margin-bottom: 10px;
}

.table.wage-table {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.wage-table tr {
    border-bottom: 1px solid #e8e8e8 !important;
}

.wage-table td {
    padding: 5px 10px 5px 0px;
}

.wage-data-tb2 {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 13px;
    /* border: 1px solid #ccc; */
}

.wage-data-tb2 input {
    max-width: 170px;
    border-radius: 0px;
    border: 1px solid #ccc;
    margin: 5px 0px;
    min-height: 36px;
}

.table.wage-data-tb2 th {
    border-bottom: 0px solid #ccc !important;
}

.wage-data-tb {
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #ccc;
}

.table.wage-data-tb th {
    border-bottom: 1px solid #ccc !important;
}

.wage-data-tb td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.css-1s2u09g-control {
    border-radius: 0px !important;
    min-height: 50px !important;
    border: 1px solid #979797 !important;
}

.css-6j8wv5-Input {
    border-radius: 0px !important;
    min-height: 60px !important;
}

.wage-ttl {
    margin: 30px 20px 20px 20px;
    text-transform: uppercase;
}

.wage-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px 10px;
    border: 0px;
    background-color: var(--main-color);
    color: var(--white-color);
}

table.table.wage-table td,
table.table.wage-data-tb,
table .wage-data-tb2 {
    font-size: 13px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: #000000a1 !important;
}

.ReactModalPortal {
    z-index: 99;
    position: relative;
    background-color: #fff;
}

.ReactModal__Content {
    max-height: 600px;
}

#companyJobsModal {
    max-width: 700px !important;
    margin: auto !important;
    max-height: 502px;
    width: calc(100% - 30px) !important;
    display: inline-table;
}

.welcome-new {
    /* min-width: 768px; */
    padding: 40px 20px 10px 20px;
    /* background-image: url('../../images/welcome_bg.png') !important; */
    background-image: url(../../images/welcome_bg.png);
    background-position-y: 0px;
    background-size: cover;
}

.welcome-new p {
    padding: 20px 20px;
    font-size: 17px;
    color: var(--black-color);
    background: rgba(255, 255, 255, 0.4);
    font-weight: 500;
}

.hd_footer .btn {
    width: 175px;
    margin: auto;
}

.welcome-new .hd_footer {
    max-width: 560px;
    margin: auto;
    /* background-color: rgba(0, 0, 0, 0.7); */
    width: 100%;
    padding: 0px 05px 30px 05px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.welcome-new .hd_footer h4 {
    color: #fff;
}

.welcome-new .hd_footer hr {
    border-bottom: 1px solid #fff;
    margin: 20px 0px;
}

.welcome_wp .hd_wp {
    width: 100%;
    height: auto;
    padding: 30px;
    background: #222 url('../../images/bg-patter-1.png');
    text-align: center;
}

.welcome_wp .hd_wp h2 {
    color: #f26222;
    font-size: 24px;
}

.welcome_wp .hd_wp img {
    width: 300px;
}

.welcome_wp .hd_content {
    padding: 30px;
    text-align: center;
}

.welcome_wp .hd_content p {
    font-size: 17px;
    margin-bottom: 0;
}

.welcome_wp .hd_footer {
    padding: 0 30px 30px 30px;
    display: flex;
    justify-content: center;
}

.welcome_wp .hd_footer .btn {
    font-weight: 500;
    margin: 0 8px;
}

.object-fit {
    object-fit: cover;
}

.alertFail {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.wdata {
    min-height: 30px;
}

.loadingIcon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color: darkred;
    z-index: 9999;
    opacity: 99;
}

.load-wrap {
    width: 100%;
    height: 100%;
    background-color: white;
    position: fixed !important;
    z-index: 9999999;
    left: 0px;
    top: 0px;
}

.max-logo-sz {
    max-width: 200px;
}


/* .loadingIcon {
    width: 100%;
    height: 100%;
    background-color: white;
    position: fixed !important;
    z-index: 9999999;
    left: 0px;
    top: 0px;
}

.load-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color: darkred;
    z-index: 9999;
    opacity: 99;
} */

.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 3px;
}


/* Filter POPUP CSS */

.filter-modal {
    margin: 0rem !important;
    padding: 0px;
    width: 100%;
}

.filter-modal .modal-dialog {
    margin: 0px;
    width: 100%;
    max-width: initial !important;
}

.filter-modal .modal-content {
    border-radius: 0px;
    border: 0px !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: black;
    background-color: var(--second-color);
    border-color: var(--second-color);
    box-shadow: none !important;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.avl-job {
    display: grid;
    grid-template-columns: 140px auto;
}

.avl-logo {
    width: 120px;
    height: 120px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avl-logo img {
    width: 100%;
}

.perks-avl img {
    max-width: auto;
    max-height: 26px;
    margin: 0px 10px;
}

.perks-avl-icon {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em 0em;
}

.perks-avl-icon div {
    text-align: center;
}

@media (max-width:1024px) and (min-width:768px) {
    .perks-avl-icon {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .perks-avl img {
        margin: 0px 5px;
    }
}

.avl-lmt a {
    max-width: 240px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.avl-ft-14 {
    font-size: 14px;
    font-weight: 500;
}


/* plugin select style */

.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus,
.css-1pahdxg-control {
    border-color: orange !important;
    box-shadow: 0px 0px 0px 1px orange !important;
}

.rotate-90 {
    transform: rotate(90deg);
}

.clr-white {
    color: white;
}

.oa {
    font-size: 70px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.jsb-round {
    background-color: #fff;
    padding: 5px;
    box-shadow: 1px 1px 11px rgb(0 0 0 / 10%);
    border-radius: 100px;
    right: 15px !important;
    width: 55px;
    height: 55px;
    line-height: 43px;
    padding: 5px;
    top: 5px !important;
}

.js-badge {
    max-width: 75px;
    max-height: 75px;
    right: -10px;
    top: -3px;
    position: absolute;
}

.js-badge-d {
    max-width: 75px;
    max-height: 75px;
    left: 10px;
    top: 10px;
    position: relative;
}

.js-badge img {
    max-height: 50px;
}

.js-d-badge {
    max-width: 60px;
    margin-right: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
    .oa {
        font-size: 42px;
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .app-icons h4 {
        text-align: center;
    }
    .app-icons .appicons {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}


/*== start of code for tooltips ==*/

.tool {
    cursor: help;
    position: relative;
    text-align: center;
}

.tool-pos-ab {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
}


/*== common styles for both parts of tool tip ==*/

.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
}


/*== pointer tip ==*/

.tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #232323 transparent transparent transparent;
    bottom: 100%;
    content: "";
    margin-left: -0.5em;
    transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26), opacity .65s .5s;
    transform: scale(.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
    transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
    /* bottom: -10px; */
}


/*== speech bubble ==*/

.tool::after {
    background: #232323;
    border-radius: .25em;
    top: -80px;
    color: #EDEFF0;
    content: attr(data-tip);
    margin-left: -8.75em;
    padding: 1em;
    transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
    transform: scale(.6) translateY(50%);
    width: 17.5em;
    /* bottom: 10px; */
}

.tool:hover::after,
.tool:focus::after {
    transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26);
}

.f18 {
    font-size: 17px;
}

.fw600 {
    font-weight: 600 !important;
}

.industry-tag {
    display: flex;
    flex-flow: wrap;
}

.industry-tag span {
    margin-bottom: 5px;
}

.industry-tag a {
    font-size: 13px;
}


/*
.css-1s2u09g-control:focus {
    border-color: orange !important;
    box-shadow: 0px 0px 0px 1px orange !important;
} */


/* edu-training
-------------------------*/

.image-list._3col {
    display: grid;
    grid-gap: 2em;
    gap: 2em;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
}

.image-list._3col li {
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: center;
}

.image-list._3col img {
    min-width: 180px;
    max-width: 200px;
}

@media (min-width:640px) and (max-width:1200px) {
    .image-list._3col {
        grid-template-columns: 1fr 1fr;
        padding-left: 0px;
    }
}

@media (min-width:320px) and (max-width:640px) {
    .image-list._3col {
        grid-template-columns: 1fr;
        padding-left: 0px;
    }
}

.ofx-h {
    overflow-x: hidden;
}

.cs-social img {
    width: 42px;
}

.threedot:after {
    content: '\2807';
    font-size: 3em;
    color: #2e2e2e
}

.thrdicon {
    color: #222;
    font-size: 1.8em;
    border: 0;
    background: transparent;
    position: relative;
    z-index: 99;
}

.avls {
    position: relative;
    z-index: 9;
}

.avls .dropdown-menu {
    /* top: -55px !important; */
    left: -132px !important;
    z-index: 0;
    box-shadow: -5px 5px 15px rgb(0 0 0 / 30%);
    transform: inherit !important;
    padding: 3px;
    border-radius: 10px;
}

.avls .dropdown-menu a {
    font-size: 13px;
}

.avls .tool::after {
    background: #232323;
    color: #fff !important;
    border-radius: 0.25em;
    top: -80px;
    color: #EDEFF0;
    content: attr(data-tip);
    margin-left: -8.75em;
    padding: 1em;
    transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
    transform: scale(.6) translateY(50%);
    width: 17.5em;
}

.avls .tool::before,
.avls .tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.pwdShowHideClsPostResume {
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 30px;
    bottom: 20px;
}

.county-top {
    color: #4c4c4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-right: 10px;
    width: 100%;
    max-width: 580px;
    font-weight: 600;
}

.county-top label {
    color: #bfbfbf;
    font-size: 15px;
}

.county-top img {
    max-width: 40px;
    margin-left: 5px;
}

.county-top ul {
    margin-bottom: 0px;
}

.county-top li {
    margin-right: 10px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.ct-next {
    padding: 5px;
    width: 100%;
}

.ct-next span {
    padding: 5px 5px;
    border-radius: 100px;
    font-size: 15px;
    min-width: 100px;
    color: var(--second-color);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width:480px) {
    .ct-next ul {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.0em 0.5em;
    }
    .counties {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        gap: 10px;
        max-width: 265px;
    }
}

.counties-serv {
    display: none;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .county-top {
        max-width: 490px;
    }
    .ct-next span,
    .county-top label {
        font-size: 11px;
    }
}

@media screen and (max-width:1400px) {
    .ed-search {
        max-height: 35px !important;
    }
    .dir-search {
        top: -4px !important;
        right: -4px;
        position: absolute;
        transform: scale(0.55);
        padding-left: 20px !important;
    }
}

@media screen and (max-width:991px) {
    .county-top {
        max-width: 100%;
    }
    .ct-next {
        background-color: transparent;
        display: block;
        text-align: center;
    }
    .county-top li {
        margin-right: 30px;
    }
    .counties-serv {
        display: block;
    }
}

.ytp-cued-thumbnail-overlay-image:before {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 420px;
    content: '';
    position: absolute;
}

.up-vid .carousel-inner,
.video-section2 .carousel-inner {
    background-color: #000;
}

.up-vid .carousel-control-prev-icon {
    background-color: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-size: 50%;
    margin: auto;
}

.up-vid .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-size: 50%;
    margin: auto;
}

.up-vid .carousel-control-next-icon,
.up-vid.carousel-control-prev-icon,
.vid-gal .carousel-control-next-icon,
.vid-gal .carousel-control-prev-icon {
    display: block;
}

.up-vid .carousel-control-next,
.up-vid .carousel-control-prev {
    opacity: 1;
}

.vid-gal .carousel-control-prev-icon {
    background-color: rgba(0, 0, 0, 0.4);
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-size: 80%;
    margin: auto;
}

.vid-gal .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.4);
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-size: 80%;
    margin: auto;
}

.vid-gal .carousel-indicators {
    bottom: 0px;
    overflow: hidden;
}

.vid-gal .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
}

.videoInputErrorClass {
    border: 1px;
    border-color: red;
    border-style: solid;
}

.vd-ad-rm {
    vertical-align: middle;
}

.dis_none {
    display: none !important;
}

.click {
    text-decoration: underline;
    font-size: 22px;
    font-weight: 700;
    color: #0045ad;
}

.facebook-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.facebook-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.cp-image img,
.js-img img {
    width: 100%;
}

.ppv figure {
    margin: inherit !important;
}

.se-component img {
    width: auto;
    max-width: 100%;
}

.vhidden {
    visibility: hidden;
}

.sun-editor .se-resizing-bar {
    display: none !important;
}

.editbutton {
    cursor: pointer;
}

.text_abovehead {
    padding: 10px;
    background: #cccccc2b;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: -10px;
}

.cards {
    display: flex;
    width: 100%;
}

.card-ld {
    /*margin: 0px 10px;*/
    width: 100%;
    background: #fff;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); */
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    padding: 0px 20px;
}

.card-ld .image img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.card-ld .content {
    padding: 0px 20px;
}

.card-ld.is-loading .image,
.card-ld.is-loading h2,
.card-ld.is-loading p,
.card-ld.is-loading span {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.5s shine linear infinite;
    animation: 1.5s shine linear infinite;
}

.spanl {
    display: flex;
    justify-content: start;
    gap: 30px;
    margin-bottom: 30px;
}

.lastspan {
    margin-left: auto;
}

.card-ld.is-loading .image {
    height: 120px;
    width: 120px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.card-ld.is-loading h2 {
    height: 30px;
}

.card-ld.is-loading span {
    height: 20px;
    display: flex;
    width: 100px;
}

.card-ld.is-loading p {
    height: 50px;
    margin-bottom: 0px;
}

@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.htl {
    margin: 10px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); */
    display: grid;
    /* grid-template-columns: 150px 1fr; */
    align-items: center;
    padding: 20px;
}

.htl .image img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.htl .content {
    padding: 20px 30px;
}

.htl.is-loading .image,
.htl.is-loading h2,
.htl.is-loading p,
.htl.is-loading span {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.5s shine linear infinite;
    animation: 1.5s shine linear infinite;
}

.spanl {
    display: flex;
    justify-content: start;
    gap: 30px;
}

.lastspan {
    margin-left: auto;
}

.htl.is-loading .image {
    height: 150px;
    width: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.htl.is-loading h2 {
    height: 30px;
}

.htl.is-loading span {
    height: 20px;
    display: flex;
    width: 100px;
}

.htl.is-loading p {
    height: 70px;
}

@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.jobsummary-content h4 strong {
    font-size: 17px;
    /* font-weight: 400 !important; */
}

.jobsummary-content span {
    font-size: 17px;
    /* font-weight: 400 !important; */
}

.jobsummary-content li h4 {
    font-size: 14px;
    font-weight: 400;
}

.jobsummary-content br {
    display: none;
}

.avl-job h4 strong {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
}

.avl-job li h4 {
    font-size: 14px;
    font-weight: 400;
}

.list-mb h4 {
    margin-bottom: 0px;
}

.list-mb span,
.list-mb p,
.list-mb div {
    font-size: 17px !important;
}

.list-mb table,
.list-mb figure {
    border-bottom: 0px !important;
}

.list-lable-ed {
    background-color: #87ceeb57;
    text-align: center;
}

.list-lable-ed p {
    font-size: 13px;
    padding: 5px;
    margin-bottom: 0px;
}

.list-lable-ed span {
    font-weight: 500;
    color: #d61115;
}

.homevideowrapper {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;

}

.homefeaturedvideoitem
{
    max-height: 200px;
    overflow: hidden;
    display: flex;
    width: 330px;
    background: black;
    align-items: center;
}

@media screen and (max-width:480px) {
    .homevideowrapper {
       flex-direction: column;
    
    }
}